import { ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessUnitService, TermsService } from '../../../service';
import FormInput from '../../FormInputFilter';
import { ErrorTreatment } from '../../../utils';
import Spinner from '../../Spinner';
import useAuth0Credentials from '../../../hooks/useAuth0Credentials';
import { updatePudoData } from '../../../redux/pudo/pudoSlice';


const TermModal = (): ReactElement => {
  const businessUnitService = new BusinessUnitService();
  const { userId } = useAuth0Credentials();

  const pudoResponse = useSelector((state: any) => state.pudoResponse)
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [show, setShow] = useState(false);
  const [acceptedTerm, setAcceptedTerm] = useState(false);

  const submit = () => {
    if (acceptedTerm) {
      setError('');
      setProcessing(true);
      businessUnitService
      .postServiceTerm(userId)
      .then(() => {
        dispatch(updatePudoData({
          ...pudoResponse,
          accepted_service_term: true
        }));
        setShow(false);
      })
      .catch(error => {
        console.log(error.data);
        setError(ErrorTreatment(error)[0].message);
      })
      .finally(() => {
        setProcessing(false);
      });
    } else {
      setError('Marque a caixa para prosseguir');
    }
  };

  useEffect(() => {
    if (
      !pudoResponse.accepted_service_term
      && pudoResponse.active_login
      && !pudoResponse.password_expired
      && pudoResponse.already_checked
    ) {
      setShow(true);
    }
  }, [pudoResponse]);

  return (
    <Modal show={show} size={'sm'} scrollable>
      <Modal.Header>
        <h4 className="modal-title">Termos de Uso</h4>
      </Modal.Header>
      <Modal.Body>
        {/* <div dangerouslySetInnerHTML={{ __html: data }} /> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormInput type='checkbox' name='terms' checked={acceptedTerm} onChange={() => setAcceptedTerm(!acceptedTerm)} />
          <label htmlFor="terms" className=''>
            Li e concordo com os&nbsp;
            <a href="https://www.pontodrops.com.br/termos-de-uso" target='_blank'>termos de uso</a>
          </label>
        </div>
        
        {error && (
          <div>
            <p className="text-danger mt-3 mb-0 fs-6">{error}</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="green" onClick={submit} disabled={processing} >
          {processing ? (
            'Carregando...'
          ) : 'Aceitar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermModal;

import {
  EnumStateCode,
  EnumTicketType,
  EnumTicketStatus,
  AccountType,
  BusinessUnitActivationStatus,
  LegalEntityType,
  EnumPaymentStatus,
  PlatformFetcherOrderError,
  PlatformsOrderFetcher,
  DeliveryType,
  BtnSelectedType,
  PlatformFetcherOrderTOStatus,
  OrderReadAction,
  EnumServiceType,
  CreatedByType,
} from '.';
import { OperationProps } from '../types/Operation';

const EnumOptionsCreatedByType = [
  { label: 'Marketplace', value: CreatedByType.Marketplace },
  { label: 'Client', value: CreatedByType.Client },
];

const EnumOptionsServiceType = [
  { label: 'Reverse', value: EnumServiceType.Reverse },
  { label: 'Delivery', value: EnumServiceType.Delivery },
];

const EnumOptionsOrderReadAction = [
  { label: 'Drop', value: OrderReadAction.Drop },
  { label: 'Pickup', value: OrderReadAction.Pickup },
];

const EnumOptionsStateCode = [
  { label: 'Sem Pagamento', value: EnumStateCode.Draft },
  { label: 'Aguardando Postagem', value: EnumStateCode.Ready },
  { label: 'Cancelado', value: EnumStateCode.Canceled },
  { label: 'Expirado', value: EnumStateCode.Expired },
  { label: 'Dropado', value: EnumStateCode.Dropped },
  { label: 'Em Entrega', value: EnumStateCode.InTransit },
  { label: 'Entregue', value: EnumStateCode.Delivered },
  { label: 'A Coletar', value: EnumStateCode.ToCollect },
  {
    label: 'Coletado Transportadora',
    value: EnumStateCode.CollectedByCarrier,
  },
];

const EnumOptionsTicketType = [
  { label: 'Acareação', value: EnumTicketType.Complaint },
  { label: 'Coleta', value: EnumTicketType.Collect },
  { label: 'Ouvidoria', value: EnumTicketType.Sac },
  { label: 'Postagem', value: EnumTicketType.Post },
  { label: 'Reclamação', value: EnumTicketType.Confrontation },
  { label: 'Sugestão', value: EnumTicketType.Suggestion },
  { label: 'Vencimento data da etiqueta', value: EnumTicketType.DueDateLabel },
  { label: 'Vencimento data de coleta', value: EnumTicketType.DueDateCollect },
  { label: 'Financeiro', value: EnumTicketType.Financial },
];

const EnumOptionsTicketStatus = [
  { label: 'Novo', value: EnumTicketStatus.New },
  { label: 'Fechado com Êxito', value: EnumTicketStatus.SuccessClosed },
  { label: 'Em Atendimento', value: EnumTicketStatus.InAttendance },
  { label: 'Pendente Seller', value: EnumTicketStatus.PendingSeller },
  { label: 'Cancelado', value: EnumTicketStatus.Cancelled },
  { label: 'Fechado sem Êxito', value: EnumTicketStatus.UnsuccessfullyClosed },
];

const EnumOptionsAccountType = [
  { label: 'Corrente', value: AccountType.ContaCorrente },
  { label: 'Poupança', value: AccountType.Poupança },
];

const EnumOptionsBusinessUnitActivationStatus = [
  { label: 'Aprovado', value: BusinessUnitActivationStatus.Approved },
  { label: 'Pendente', value: BusinessUnitActivationStatus.Pending },
  { label: 'Rejeitado', value: BusinessUnitActivationStatus.Rejected },
];

const EnumOptionsLegalEntityType = [
  { label: 'EPP', value: LegalEntityType.EPP },
  { label: 'ME', value: LegalEntityType.ME },
  { label: 'MEi', value: LegalEntityType.MEI },
  { label: 'Não Optante', value: LegalEntityType.None },
];

const EnumStatesCodeAndPayment = [
  {
    value: `${EnumStateCode.Ready}.${EnumPaymentStatus.Approved}`,
    label: 'Aguardando Postagem',
    color: '#fdc62d',
    status: EnumStateCode.Ready,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.ProcessingDrop}.${EnumPaymentStatus.Approved}`,
    label: 'Em processamento',
    color: '#fdc62d',
    status: EnumStateCode.ProcessingDrop,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.Dropped}.${EnumPaymentStatus.Approved}`,
    label: 'Dropado',
    color: '#26b68e',
    status: EnumStateCode.Dropped,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.InTransit}.${EnumPaymentStatus.Approved}`,
    label: 'Em Entrega',
    color: '#26b68e',
    status: EnumStateCode.InTransit,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.Delivered}.${EnumPaymentStatus.Approved}`,
    label: 'Entregue',
    color: '#26b68e',
    status: EnumStateCode.Delivered,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.ToCollect}.${EnumPaymentStatus.Approved}`,
    label: 'A Coletar',
    color: '#26b68e',
    status: EnumStateCode.ToCollect,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: `${EnumStateCode.CollectedByCarrier}.${EnumPaymentStatus.Approved}`,
    label: 'Coletado Transportadora',
    color: '#26b68e',
    status: EnumStateCode.CollectedByCarrier,
    payment_status: EnumPaymentStatus.Approved,
  },
];

type PlatformFetcherOrderErrorLabel = {
  [key: string]: string;
};

const PlatformFetcherOrderErrorLabel: PlatformFetcherOrderErrorLabel = {
  [PlatformFetcherOrderError.FetchError]: 'Erro de busca.',
  [PlatformFetcherOrderError.OrderNotFound]: 'Pedido não encontrado.',
  [PlatformFetcherOrderError.OrderAlreadyDroppedOnPlatform]:
    'Pedido já caiu na plataforma.',
  [PlatformFetcherOrderError.OrderAlreadyOnSystem]: 'Pedido já no sistema.',
  [PlatformFetcherOrderError.LayoutChanged]: 'Modelo alterado.',
  [PlatformFetcherOrderError.ConvertingOrderScenario6]:
    'Cenário de conversão de pedido 6.',
  [PlatformFetcherOrderError.DropInPlatform]: 'Dropado na plataforma',
  [PlatformFetcherOrderError.DropInPudoSystem]: 'Dropado no PUDO System',
  [PlatformFetcherOrderError.Authentication]: 'Erro de autenticação',
  [PlatformFetcherOrderError.SetPickupError]:
    'Adicionar pedido na lista de pickup',
  [PlatformFetcherOrderError.RemovePickupError]:
    'Remover pedido da lista de pickup',
  [PlatformFetcherOrderError.CompletePickupError]: 'Completar lista de pickup',
};

const OperationsPlatform: OperationProps[] = [
  {
    deliveryType: DeliveryType.shopee,
    btnSelectedType: BtnSelectedType.senderShopee,
    name: 'Shopee',
    platform: PlatformsOrderFetcher.Shopee,
    login_enabled: false,
  },
];

const DeliveryShopeePageDefault = {
  disabledButtonFinish: true,
  loadingButtonFinish: false,
  deliveryModal: false,
  updateFetcheCountPickup: false,
};

const EnumOptionsPlatformFetcherOrderTOStatus = [
  { label: 'Selecione...', value: undefined },
  { label: 'Ativa', value: PlatformFetcherOrderTOStatus.Active },
  { label: 'Concluída', value: PlatformFetcherOrderTOStatus.Completed },
];

export {
  EnumOptionsStateCode,
  EnumOptionsTicketType,
  EnumOptionsTicketStatus,
  EnumOptionsAccountType,
  EnumOptionsBusinessUnitActivationStatus,
  EnumOptionsLegalEntityType,
  EnumStatesCodeAndPayment,
  PlatformFetcherOrderErrorLabel,
  OperationsPlatform,
  DeliveryShopeePageDefault,
  EnumOptionsPlatformFetcherOrderTOStatus,
  EnumOptionsOrderReadAction,
  EnumOptionsServiceType,
  EnumOptionsCreatedByType,
};

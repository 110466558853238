import type { AxiosResponse } from 'axios';
import HttpBusinessUnitService from '../../config/HttpBusinessUnit';
import type { GetPudoResponse, PutPasswordProps } from './businessUnit.type';

export class BusinessUnitService {
  api: HttpBusinessUnitService;

  constructor() {
    this.api = new HttpBusinessUnitService();
  }

  auxUrl = 'business-units/​';

  getPudo = (id: string): Promise<AxiosResponse<GetPudoResponse>> => {
    return this.api.get(`v1/business-units/${id}`);
  };

  updatePudo = (payload: any, id: string) => {
    return this.api.patch(`v1/business-units/${id}`, payload);
  };

  getPlatformOrderFetcherLogin = () => {
    return this.api.get('v1/business-units/platform_order_fetcher_login');
  };

  getBusinessIdByOperator = async (operatorId: string): Promise<string> => {
    const response = await this.api.get(
      `v1/business-units/operator/${operatorId}/business_unit_id`
    );

    return response.data;
  };
  
  putBusinessPasswordOperator = (payload: PutPasswordProps) => {
    return this.api.put(
      `v1/business-units/operator/${payload.id}/password`,
      payload,
    );
  };

  putBusinessPassword = (payload: PutPasswordProps) => {
    return this.api.put(
      `v1/business-units/${payload.id}/password`,
      payload,
    );
  };

  postServiceTerm = (id: string): Promise<AxiosResponse<boolean>> => {
    return this.api.post(
      `v1/service-terms/agreement`,
      { id },
    );
  };
}

import type { AxiosError } from "axios";
import { EnumStateCode, EXCEPTIONS } from "../enum";
import { EnumOptionsStateCode } from "../enum/options";
import type { TFunction } from "react-i18next";

export type TypeErrorTreatment = {
  message: string;
  codigo?: number;
};

const ErrorTreatment = (error: any): TypeErrorTreatment[] => {
  const arrayErros: TypeErrorTreatment[] = [];
  if (error.response) {
    switch (error.response.status) {
      case 400:
        if (typeof error.response.data === 'string') {
          arrayErros.push({
            message: error.response.data,
            codigo: 400,
          });
        } else if (error.response.data && Array.isArray(error.response.data)) {
          error.response.data.forEach((el: any) => {
            arrayErros.push({ message: el.message, codigo: 400 });
          });
        } else {
          arrayErros.push({
            message: 'Ocorreu um erro inesperado',
            codigo: 400,
          });
        }
        break;
      case 403:
        arrayErros.push({ message: 'Sem permissão', codigo: 403 });
        break;
      case 404:
        arrayErros.push({ message: 'Não encontrado ou inválido', codigo: 404 });
        break;
      case 500:
        arrayErros.push({ message: 'Erro interno do servidor', codigo: 500 });
        break;
      default:
        arrayErros.push({ message: 'Ocorreu um erro inesperado' });
        break;
    }
  } else {
    arrayErros.push({ message: 'Erro interno do servidor' });
  }
  return arrayErros;
};

const ErrorFormatString = (error: any, t: any) => {
  let message = '';
  error.forEach((el: TypeErrorTreatment) => {
    message += `${t(el.message)} ${error.length > 1 ? `{'\n'}` : ''}`;
  });
  return message;
};

export const getErrorMessageOrDefault = (errorMessage: string) => {
  const errors = {
    "3c874156-f7af-4c96-a1f0-083f053ba6a6": "Esse pacote é para outra transportadora"
  };
  
  return errors?.[errorMessage.trim() as keyof typeof errors] || errorMessage;
};


type ErrorObject = {
  current_status_code: string
  current_status_code_enum: number
  invalid_trigger: string
  invalid_trigger_enum: number
}

export const handleStateMachineError = (status: string, alt?: string) => {
  const statusText = EnumOptionsStateCode.find(item => item.value === EnumStateCode?.[status as keyof typeof EnumStateCode])?.label ?? alt;  

  return `Erro inesperado: Pedido com status ${statusText}. Por favor, verifique as informações do pedido e tente novamente.`
}

export const stateMachineMessageTreatment = (error: AxiosError<any>, t: TFunction<"translation", undefined>) => {
  const messageError = ErrorFormatString(ErrorTreatment(error), t);
  const errorCode = error.response?.data?.[0]?.code;
  const errorMessage: string = error.response?.data?.[0]?.message;
  const errorObject: ErrorObject | undefined = error.response?.data?.[0]?.object;

  const status = errorObject?.current_status_code ?? errorMessage.match(/'([^']+)'/)?.[1]

  if(
    (errorCode === EXCEPTIONS.UnexpectedError 
      || errorCode === EXCEPTIONS.InvalidState) 
    && status
  ) {
    return handleStateMachineError(status, errorObject?.current_status_code);
  } else {
    return messageError;
  }
}

export { ErrorTreatment, ErrorFormatString };

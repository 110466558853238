// @flow
import { combineReducers } from 'redux';

import Layout from './layout/reducers';
import { GetPudoResponseReducer } from './pudo/pudoSlice';

const rootReducer = (combineReducers({
    Layout,
    pudoResponse: GetPudoResponseReducer,
}));

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

import React, { type LazyExoticComponent, type ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// auth
const Logout = React.lazy(() => import('../pages/account/Logout'));

const Profile = React.lazy(() => import('../pages/profile'));
const ChangePassword = React.lazy(() => import('../pages/profile/changePassword'));

const CheckFirstAccess = React.lazy(() =>
  import('../pages/pudo/checkFirstAccess')
);
const Packages = React.lazy(() =>
  import('../pages/packages')
);
const ManageOrders = React.lazy(() =>
  import('../pages/manageOrders')
);
const EndCostumerDetails = React.lazy(() =>
  import('../pages/packages/endCostumerDetails')
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import('../pages/error/PageNotFoundAlt')
);
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const RedirectApp = React.lazy(() => import('../pages/account/RedirectApp'));

interface RouteChildren {
  path: string;
  name: string;
  badge: {
      variant: string;
      text: string;
  };
  component: (() => JSX.Element) | LazyExoticComponent<(props: any) => JSX.Element>;
  route: typeof Route | ((props: any) => JSX.Element);
  exact: boolean;
}

export interface RouteItem {
  path: string;
  name: string;
  icon?: string;
  header?: string;
  badge?: {
      variant: string;
      text: string;
  };
  component?: any;
  route?: any;
  exact?: boolean;
  children?: RouteChildren[];
}

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  name: 'Home',
  component: () => <Redirect to="/pudo/check-access" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    {
      path: '/packages',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Packages,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/package-end-costumer-details/:id',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: EndCostumerDetails,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/package-end-costumer-details-document/:code',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: EndCostumerDetails,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/manage-orders',
      name: 'manageOrders',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ManageOrders,
      route: PrivateRoute,
      exact: true
    },
    // {
    //   path: '*',
    //   name: 'notFound',
    //   badge: {
    //     variant: 'success',
    //     text: '3',
    //   },
    //   component: ErrorPageNotFoundAlt,
    //   route: PrivateRoute,
    //   exact: true,
    // },
  ],
};

const profileRoutes = [
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    exact: true,
    route: PrivateRoute,
  },
  {
    path: '/profile/change-password',
    name: 'ChangePassword',
    exact: true,
    component: ChangePassword,
    route: PrivateRoute,
  },
];

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFoundAlt,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
  {
    path: '/redirect-app',
    name: 'RedirectApp',
    component: RedirectApp,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RouteItem[]) => {
  let flatRoutes: RouteItem[] = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
];

const routesDefaultLayout = [
  {
    path: '/pudo/check-access',
    name: 'Init',
    component: CheckFirstAccess,
    route: PrivateRoute,
  },
];

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...profileRoutes
];
const publicRoutes = [
  rootRoute,
  ...authRoutes,
  ...otherPublicRoutes,
  ...routesDefaultLayout,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };

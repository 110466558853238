// @flow
import { createStore, applyMiddleware, type compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers, { type RootState } from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export function configureStore(initialState: Partial<RootState>) {
    let store;

    if (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) {
        store = createStore(reducers, initialState, window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'](applyMiddleware(...middlewares)));
    } else {
        store = createStore(reducers, initialState, applyMiddleware(...middlewares));
    }
    sagaMiddleware.run(rootSaga);
    return store;
}
